@import 'src/styles/z-indexes';
@import 'src/styles/animations';
@import 'src/styles/mixins';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal {
  position: absolute;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  z-index: $modalZIndex;

  @include show();

  &--centered {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  &--hide-start {
    @include hide();
  }

  &--hide-end {
    display: none;
  }
}
