@import 'src/styles/media';
@import 'src/styles/mixins';
@import 'src/styles/z-indexes';

.snack-list-wrapper {
  height: 0;
  width: 100vw;
  position: absolute;
  bottom: -100vh;
  left: 0;
}

.snack-list {
  list-style: none;
  position: absolute;
  bottom: 5rem;
  left: 50%;
  width: calc(100% - 2rem);
  transform: translateX(-50%);
  z-index: $snackZIndex;

  @include media(tablet) {
    max-width: 512px;
  }

  @include media(desktop) {
    bottom: 0;
  }
}
