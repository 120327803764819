@import 'src/styles/mixins';

.content {
  color: rgba(255, 255, 255, 1);
  flex: 1;
  opacity: 0;
  overflow-y: auto;
  padding-top: 1.5rem;
  position: relative;
  width: 100%;

  @include flexBox(column, nowrap, flex-start, center);
  @include show(1000ms);
}
