@import 'src/styles/mixins';

.form-input {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
  width: 100%;

  @include flexBox(column, nowrap, space-between);

  &:last-child {
    margin-bottom: 0;
  }

  &__label {
    margin-bottom: 0.25rem;
    width: 100%;
  }

  &__input {
    border: 1px solid $transparent;
    border-radius: 0.25rem;
    font-size: 1rem;
    outline: none;
    padding: 0.25rem;
    width: 100%;

    &:focus {
      border-color: $lightBlue;
    }
  }

  &__error-message {
    padding-top: 0.25rem;
    color: $golden;
    font-size: 0.85rem;
  }

  &--disabled {
    cursor: default;
  }
}
