@import 'src/styles/mixins';
@import 'src/styles/animations';

.preloader {
  background-color: $transparentDark50;
  color: $white;

  &-content {
    min-width: 10rem;

    @include flexBoxCentered();

    &__spinner {
      height: 4rem;
      width: 4rem;

      @include flexBoxCentered();

      .spinner-icon {
        height: 100%;
        transform-origin: center;
        width: 100%;

        animation: rotation 1000ms linear infinite;
      }
    }

    &__label {
      font-size: 1.25rem;
      padding: 1rem 0;
      text-align: center;
    }
  }
}
