@import 'src/styles/media';
@import 'src/styles/mixins';
@import 'src/styles/z-indexes';

.snack-item {
  background-color: $lightBlue;
  border-radius: 4px;
  color: $white;
  margin-bottom: 0.25rem;
  padding: 1rem;
  user-select: none;
  cursor: pointer;

  @include show();

  &--hide-on {
    @include hide();
  }

  &--error {
    background-color: $red;
  }

  &--success {
    background-color: $green;
  }

  &--info {
    background-color: $blue;
  }

  &--warning {
    background-color: $yellow;
    color: $black;
  }

  &__message {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  &__secondary-info {
    width: 100%;
    font-size: 0.75rem;

    @include flexBox(row, nowrap, space-between, center);
  }
}
