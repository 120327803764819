@import 'src/styles/mixins';
@import 'src/styles/media';

.feature-by {
  height: 100%;
  width: 100%;

  @include flexBox(column, nowrap, flex-start, center);

  .main-section {
    width: 100%;
    max-width: $tablet-max-width;
    font-size: calc(10px + 1vmin);
    line-height: 1.5;
    padding: 0 1rem;

    &__title {
      color: $blueGrey;
      font-size: calc(12px + 1vmin);
      font-weight: bold;
      margin-bottom: 1rem;
    }

    &__paragraph {
      margin-bottom: 1rem;
      text-align: justify;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__list {
      padding-left: 1.5rem;

      @include flexBox(column, nowrap, flex-start, flex-start);
    }
  }

  .featured {
    color: $golden;
  }

  .text-button {
    cursor: pointer;
    user-select: none;
    outline: none;
    background-color: $transparent;

    &:active,
    &:focus {
      background-color: $transparent;
    }
  }

  .secondary-section {
    margin-top: 3rem;

    .list {
      padding-bottom: 4rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      @include flexBox(row, wrap, center, center);

      &-item {
        display: inline-block;
        height: 2.5rem;
        width: 2.5rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
