@import 'src/styles/mixins';

.form-button {
  margin-right: 1rem;
  width: 6rem;

  @include borderedButton();

  &:last-child {
    margin-right: 0;
  }
}
