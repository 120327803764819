@import 'animations';
@import 'colors';

@mixin show($delay: 300ms) {
  animation: fadeIn $delay ease-out forwards;
}

@mixin hide($delay: 300ms) {
  animation: fadeOut $delay ease-out forwards;
}

@mixin flexBox($direction: row, $wrapping: wrap, $justifyContent: flex-start,
  $alignItems: flex-start, $inline: false) {
  @if $inline {
    display: inline-flex;
  } @else {
    display: flex;
  }
  flex-flow: $direction $wrapping;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin flexBoxCentered($direction: column, $wrapping: nowrap, $inline: false) {
  @include flexBox($direction, $wrapping, center, center, $inline);
}

@mixin boxShadow($horizontal: 2px, $vertical: 2px, $size: 5px, $color: $transparentWhite30) {
  box-shadow: $horizontal $vertical $size $color;
}

@mixin textShadow($horizontal: 2px, $vertical: 2px, $size: 5px, $color: $transparentWhite30) {
  text-shadow: $horizontal $vertical $size $color;
}

@mixin basicButton() {
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
  color: $transparentWhite50;
  cursor: pointer;
  font-size: 1rem;
  outline: none;
  padding: 0.5rem;
  transition: background-color 300ms, color 300ms, box-shadow 300ms;

  @include flexBoxCentered(row, nowrap, true);

  &:hover {
    background-color: $blueGrey;
    color: $white;

    @include boxShadow(0, 0, 5px, $blueGrey);
  }

  &:active {
    background-color: $blueGrey;
    box-shadow: none;
  }
}

@mixin borderedButton() {
  @include basicButton();

  border: 1px solid $transparentWhite50;
}

@mixin menuItemAction($type: button) {
  background-color: $transparentWhite50;
  border-radius: 0.25rem;
  border: none;
  color: $transparentDark50;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  outline: none;
  padding: 0.5rem;
  text-align: left;
  transition: color 300ms, background-color 300ms, box-shadow 300ms, text-shadow 300ms;
  width: 100%;

  @if $type == anchor {
    display: block;
    text-decoration: none;
  }

  &:hover {
    background-color: $transparentWhite75;
    color: $transparentDark75;

    @include boxShadow();
    @include textShadow(2px, 2px, 5px, $transparentDark30);
  }

  &:active {
    color: $transparentDark75;
    box-shadow: none;
    text-shadow: none;
  }
}
